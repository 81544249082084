import { DailyVideo, useVideoTrack } from "@daily-co/daily-react";

export default function Tile({ id }) {
  const videoState = useVideoTrack(id);

  return (
    <div className="w-64 h-36 border border-black rounded-md relative overflow-hidden flex justify-center items-center ml-1 mr-1">
      <DailyVideo
        fit="contain"
        sessionId={id}
        type="video"
        className="daily-video"
      />
    </div>
  );
}
