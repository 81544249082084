import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/accordion";
import apiVisitorSessions from "../Visitors/VisitorsApis/apiVisitorSessions";
import { Card, CardContent } from "../components/card";
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import { Button } from "../components/button";

// export function VisitorHistorySessions({ id }) {
//   const [sessionData, setSessionData] = useState();
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const fetchHistorySessions = async () => {
//       try {
//         setIsLoading(true);
//         const data = await apiVisitorSessions(id, 1, 3);
//         setSessionData(data.sessions);
//       } catch (error) {
//         toast.error(`Error loading visitor data: ${error.message}`);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchHistorySessions();
//   }, [id]);

//   if (isLoading) {
//     return (
//       <div className="mt-52">
//         <Spinner />
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col gap-4 p-4 bg-white rounded-lg">
//       <div className="font-medium">Session History</div>
//       <Accordion type="single" collapsible className="w-full">
//         {sessionData && sessionData.length > 0 ? (
//           sessionData.map((session, index) => (
//             <AccordionItem key={index} value={`item-${index + 1}`}>
//               <AccordionTrigger className="p-4 mb-4 h-12 bg-gray-200 rounded-lg">
//                 {session.start_time}
//               </AccordionTrigger>
//               <AccordionContent>
//                 <Content session={session} />
//               </AccordionContent>
//             </AccordionItem>
//           ))
//         ) : (
//           <div className="m-4 text-center bg-white rounded-lg">
//             There is no history of sessions.
//           </div>
//         )}
//       </Accordion>
//     </div>
//   );
// }

// function Content({ session }) {
//   console.log(session);

//   const {
//     attempts_to_f2f,
//     end_time,
//     pages_visited,
//     start_time,
//     time_spent_f2f,
//     total_time_spent,
//   } = session;
//   const tableData = [
//     { label: "STARTED:", value: start_time },
//     { label: "ENDED:", value: end_time },
//     { label: "DURATION:", value: total_time_spent },
//     { label: "ATTEMPTS TO F2F:", value: attempts_to_f2f },
//     { label: "TIME SPENT F2F:", value: time_spent_f2f },
//   ];

//   return (
//     <Card className="mx-auto w-full border-0 shadow-none">
//       <CardContent className="p-4">
//         <div className="grid gap-1">
//           {tableData.map((item, index) => (
//             <div
//               key={index}
//               className="grid grid-cols-1 gap-1 items-start sm:grid-cols-3"
//             >
//               <div className="font-medium text-muted-foreground">
//                 {item.label}
//               </div>
//               <div className="text-base font-semibold break-all sm:col-span-2">
//                 {item.value}
//               </div>
//             </div>
//           ))}
//           {pages_visited.length > 0 &&
//             !(pages_visited.length === 1 && pages_visited[0] === "/") && (
//               <div className="grid grid-cols-1 gap-1 items-start sm:grid-cols-3">
//                 <div className="font-medium text-muted-foreground">
//                   PAGES VISITED:
//                 </div>
//                 <div className="text-base font-semibold break-all sm:col-span-2">
//                   {pages_visited.map((page, index) => (
//                     <div key={index}>{page}</div>
//                   ))}
//                 </div>
//               </div>
//             )}
//         </div>
//       </CardContent>
//     </Card>
//   );
// }

export function VisitorHistorySessions({ id }) {
  const [sessionData, setSessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageData, setNextPageData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchHistorySessions = async (page) => {
    try {
      setIsLoading(true);
      const data = await apiVisitorSessions(id, page, 3);
      console.log(data);
      return data.sessions;
    } catch (error) {
      toast.error(`Error loading visitor data: ${error.message}`);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const prefetchNextPage = async (page) => {
    setIsLoadingMore(true);
    const nextData = await fetchHistorySessions(page);
    setNextPageData(nextData);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    const initialFetch = async () => {
      const initialData = await fetchHistorySessions(1);
      setSessionData(initialData);
      prefetchNextPage(2);
    };
    initialFetch();
  }, [id]);

  const handleLoadMore = () => {
    setSessionData((prevData) => [...prevData, ...nextPageData]);
    setCurrentPage((prevPage) => prevPage + 1);
    setNextPageData([]);
    prefetchNextPage(currentPage + 2);
  };

  if (isLoading && sessionData.length === 0) {
    return (
      <div className="mt-52">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-lg">
      <div className="font-medium">Session History</div>
      <Accordion type="single" collapsible className="w-full">
        {sessionData.length > 0 ? (
          sessionData.map((session, index) => (
            <AccordionItem key={index} value={`item-${index + 1}`}>
              <AccordionTrigger className="p-4 mb-4 h-12 bg-gray-200 rounded-lg">
                {session.start_time}
              </AccordionTrigger>
              <AccordionContent>
                <Content session={session} />
              </AccordionContent>
            </AccordionItem>
          ))
        ) : (
          <div className="m-4 text-center bg-white rounded-lg">
            There is no history of sessions.
          </div>
        )}
      </Accordion>
      {nextPageData.length > 0 && !isLoadingMore && (
        <div className="flex justify-center mt-4">
          <Button
            onClick={handleLoadMore}
            disabled={isLoading}
            className="px-4 py-2 text-gray-900 bg-gray-300 rounded-lg hover:bg-gray-200"
          >
            Load More...
          </Button>
        </div>
      )}
    </div>
  );
}

function Content({ session }) {
  console.log(session);

  const {
    attempts_to_f2f,
    end_time,
    pages_visited,
    start_time,
    time_spent_f2f,
    total_time_spent,
  } = session;
  const tableData = [
    { label: "STARTED:", value: start_time },
    { label: "ENDED:", value: end_time },
    { label: "DURATION:", value: total_time_spent },
    { label: "ATTEMPTS TO F2F:", value: attempts_to_f2f },
    { label: "TIME SPENT F2F:", value: time_spent_f2f },
  ];

  return (
    <Card className="mx-auto w-full border-0 shadow-none">
      <CardContent className="p-4">
        <div className="grid gap-1">
          {tableData.map((item, index) => (
            <div
              key={index}
              className="grid grid-cols-1 gap-1 items-start sm:grid-cols-3"
            >
              <div className="font-medium text-muted-foreground">
                {item.label}
              </div>
              <div className="text-base font-semibold break-all sm:col-span-2">
                {item.value}
              </div>
            </div>
          ))}
          {pages_visited.length > 0 &&
            !(pages_visited.length === 1 && pages_visited[0] === "/") && (
              <div className="grid grid-cols-1 gap-1 items-start sm:grid-cols-3">
                <div className="font-medium text-muted-foreground">
                  PAGES VISITED:
                </div>
                <div className="text-base font-semibold break-all sm:col-span-2">
                  {pages_visited.map((page, index) => (
                    <div key={index}>{page}</div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </CardContent>
    </Card>
  );
}
