const apiVisitorUpdate = async (visitor_id, data) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    console.log(JSON.stringify(data)); // Logging identity

    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/organizations/${org_id}/visitors/${visitor_id}`,
      {
        method: "PATCH",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Updating visitor failed");
    }

    const dataFinal = await response.json();
    return dataFinal;
  } catch (error) {
    console.error("Error updating visitor:", error);
    throw error;
  }
};

export default apiVisitorUpdate;
