import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisitorsTable from "../Visitors/VisitorsTable";
import { RxHamburgerMenu } from "react-icons/rx";
import { useContext } from "react";
import { AppLayoutContext } from "./AppLayout";
import { Card } from "../components/card";
import { Button } from "../components/button";
import apiVisitors from "../Visitors/VisitorsApis/apiVisitors";

function Visitors() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  const [totalVisitors, setTotalVisitors] = useState(null);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const data = await apiVisitors(1, 1);
        console.log(data);
        setTotalVisitors(data.total_visitors);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchVisitors();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Visitors</p>
          <p>{totalVisitors ? totalVisitors : "..."} Visitors</p>
        </div>

        <button
          className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>

      <VisitorsTable />
    </>
  );
}

export default Visitors;
