import { createContext, useState, useCallback } from "react";
import apiWorkflows from "./WorkflowApis/apiWorkflows";
import apiDeletingWorkflow from "./WorkflowApis/apiDeletingWorkflow";
import apiNewWorkflow from "./WorkflowApis/apiNewWorkflow";
import apiUpdateWorkflow from "./WorkflowApis/apiUpdateWorkflow";
import toast from "react-hot-toast";

export const WorkflowContext = createContext();

export const WorkflowProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workflows, setWorkflows] = useState([]);

  const fetchWorkflows = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiWorkflows();
      setWorkflows(data.workflows);
      console.log(data.workflows);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteWorkflow = useCallback(
    async (workflowId) => {
      try {
        await apiDeletingWorkflow(workflowId);
        toast.success("Workflow deleted successfully");
        await fetchWorkflows(); // Re-fetch the workflows after successful deletion
      } catch (error) {
        console.error("Error deleting workflow:", error);
        toast.error("Failed to delete workflow");
      }
    },
    [fetchWorkflows]
  );

  const createWorkflow = useCallback(
    async (workflowData) => {
      try {
        setIsLoading(true);
        await apiNewWorkflow(workflowData);
        toast.success("Workflow created successfully");
        await fetchWorkflows(); // Re-fetch the workflows after successful creation
      } catch (error) {
        console.error("Error creating workflow:", error);
        toast.error("Failed to create workflow");
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchWorkflows]
  );

  const updateWorkflow = useCallback(
    async (workflowId, workflowData) => {
      try {
        setIsLoading(true);
        await apiUpdateWorkflow(workflowId, workflowData);
        toast.success("Workflow updated successfully");
        await fetchWorkflows(); // Re-fetch the workflows after successful update
      } catch (error) {
        console.error("Error updating workflow:", error);
        toast.error("Failed to update workflow");
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchWorkflows]
  );

  return (
    <WorkflowContext.Provider
      value={{
        workflows,
        fetchWorkflows,
        deleteWorkflow,
        createWorkflow,
        updateWorkflow,
        isLoading,
        error,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};
