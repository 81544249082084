import CallingWindow from "./CallingWindow";
import VisitorInfoCard from "./VisitorInfoCard";
import { VisitorHistorySessions } from "./VisitorHistorySessions";
import VisitorIdentityCard from "../Visitors/VisitorsIdentityCard";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import apiVisitorData from "../Visitors/VisitorsApis/apiVisitorData";
import Spinner from "./Spinner";
import { Badge } from "../components/badge";
import { Button } from "../components/button";
import apiVisitorQualify from "../Visitors/VisitorsApis/apiVisitorQualify";
import apiVisitorDisqualify from "../Visitors/VisitorsApis/apiVisitorDisqualify";
import toast from "react-hot-toast";
import { Dialog, DialogContent } from "../components/dialog";
import { Input } from "../components/input";

import { FiEdit } from "react-icons/fi";
import apiVisitorUpdate from "../Visitors/VisitorsApis/apiVisitorUpdate";

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};
function formatTime(seconds) {
  const h = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const m = Math.floor((seconds % 3600) / 60); // remaining minutes
  const s = seconds % 60; // remaining seconds

  if (h > 0) {
    return `${h} h ${m} min`;
  } else if (m > 0) {
    return `${m} min`;
  } else {
    return `${s} sec`;
  }
}

const createInfoData = (visitorData) => {
  const isIdentified =
    visitorData?.data && Object.keys(visitorData.data).length > 0;

  return [
    {
      title: "User Info",
      status: isIdentified ? "identified" : "not identified",
      _id: visitorData?._id,
      ...(isIdentified ? visitorData.data : {}),
    },
    {
      title: "Browser Info",
      country: (
        <p className="flex gap-2">
          <span>{visitorData?.location.country}</span>
          {flagemojiToPNG(visitorData?.location.country)}
        </p>
      ),

      city: visitorData?.location?.city || "*Montreal",
      ip: visitorData?.ip || "*192.100.921.08",
      devicetype: visitorData?.device?.type || "*Mobile",
      devicename: visitorData?.device?.name || "*iPhone",
      browser: visitorData?.browser?.name || "*Safari",
    },
    {
      title: "History Overview",
      "# OF SESSIONS": visitorData?.total_browsing_sessions, // * Hardcoded
      "TOTAL DURATION": formatTime(visitorData?.total_browsing_time), // * Hardcoded
      "LAST LOGIN": visitorData?.last_login
        ? new Date(visitorData.last_login).toLocaleString()
        : "*6d ago",
      "FIRST LOGIN": visitorData?.created_at
        ? new Date(visitorData.created_at).toLocaleString()
        : "*10d ago",
      "# F2F SESSIONS": visitorData?.total_f2f_sessions, // * Hardcoded
      "TIME SPENT F2F": formatTime(visitorData?.total_f2f_time), // * Hardcoded
    },
  ];
};

function Container() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visitorData, setVisitorData] = useState();
  const [isIdentityCardOpen, setIsIdentityCardOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("Default Value");
  const inputRef = useRef(null);
  const measureRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    adjustInputWidth();
  };

  const handleInputBlur = async () => {
    setIsEditing(false);

    if (inputValue !== visitorData?.visitor?.name) {
      try {
        setIsLoading(true);
        await apiVisitorUpdate(id, { name: inputValue });
        toast.success("Visitor name updated successfully");

        //opet pozovi data za vizitora
        const data = await apiVisitorData(id);
        setVisitorData(data);
      } catch (error) {
        toast.error(`Error updating visitor name: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const adjustInputWidth = () => {
    if (measureRef.current && inputRef.current) {
      const width = measureRef.current.offsetWidth;
      inputRef.current.style.width = `${width + 15}px`; // Add some padding
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
    adjustInputWidth();
  }, [isEditing, inputValue]);

  // async function handleNotifications() {
  //   if (!visitorData) return;

  //   try {
  //     setIsLoading(true);
  //     if (visitorData.visitor?.disqualified) {
  //       await apiVisitorQualify(id);
  //       toast.success("Visitor qualified successfully");
  //     } else {
  //       await apiVisitorDisqualify(id);
  //       toast.success("Visitor disqualified successfully");
  //     }
  //     // Refetch visitor data to update the UI
  //     const updatedData = await apiVisitorData(id);
  //     setVisitorData(updatedData);
  //   } catch (error) {
  //     setError(error.message);
  //     toast.error(`Error: ${error.message}`);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        setIsLoading(true);
        const data = await apiVisitorData(id);

        setVisitorData(data);
        console.log(data);
        setInputValue(data?.visitor?.name);
      } catch (error) {
        setError(error.message);
        toast.error(`Error loading visitor data: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVisitors();
  }, [id]);

  if (isLoading) {
    return (
      <div className="mt-52">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="pt-5">
      <div className="flex flex-col gap-4 justify-between items-center px-4 py-2 w-full bg-white rounded-lg sm:flex-row sm:gap-0">
        <div className="flex items-center space-x-4">
          <Badge
            variant={
              visitorData?.visitor?.online === false ? "secondary" : "default"
            }
            className="flex justify-center items-center w-20 text-base"
          >
            {visitorData?.visitor?.online ? "online" : "offline"}
          </Badge>
          <div className="flex relative items-center">
            <Input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              disabled={!isEditing}
              className="min-w-[50px]  max-w-[150px] transition-all duration-200 ease-in-out disabled:opacity-100 border-0 "
            />
            <span
              ref={measureRef}
              className="absolute top-0 left-0 invisible whitespace-pre"
              style={{
                font: "16px / 1.5 sans-serif", // Match the input's font
                padding: "0 4px", // Match the input's padding
              }}
            >
              {inputValue}
            </span>
            <Button
              variant="ghost"
              size="icon"
              onClick={handleEditClick}
              className="ml-2"
            >
              <FiEdit className="w-4 h-4" />
            </Button>
          </div>
        </div>

        <div className="w-full sm:w-auto">
          {/* <Button
            variant="outline"
            size="sm"
            className="pl-2 mr-2 h-12 text-black bg-gray-50 hover:bg-gray-100 hover:border-1 hover:border-gray-800 md:w-48"
            onClick={handleNotifications}
          >
            {"Notifications"}
          </Button> */}

          <Button
            variant="outline"
            size="sm"
            className="pl-2 mr-2 w-full h-12 text-white bg-black sm:w-auto hover:bg-gray-800 hover:border-1 hover:border-gray-100 md:w-48 hover:text-white"
            onClick={() => setIsIdentityCardOpen(true)}
          >
            {"Identity"}
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-[1fr_20rem] xl:grid-cols-[1fr_27rem] 2xl:grid-cols-[1fr_32rem] gap-x-8 pt-5 sm:grid-cols-1 sm:gap-y-4 gap-y-4">
        <div className="flex flex-col gap-4">
          {visitorData && <CallingWindow visitorData={visitorData} />}
          <VisitorHistorySessions id={id} />
        </div>
        <div className="flex flex-col gap-4">
          {visitorData &&
            createInfoData(visitorData.visitor).map((info) => (
              <VisitorInfoCard info={info} key={info.title} />
            ))}
        </div>
      </div>

      <Dialog open={isIdentityCardOpen} onOpenChange={setIsIdentityCardOpen}>
        <DialogContent className="p-0 rounded-lg">
          {visitorData && (
            <VisitorIdentityCard
              initialData={visitorData.visitor?.data}
              visitor_id={id}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Container;
