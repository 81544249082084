import { useContext } from "react";
import { AppLayoutContext } from "./AppLayout";
import { useNavigate } from "react-router-dom";

import Container from "../ui/Container";

import { IoArrowBack } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";

function Dashboard() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center">
        <button
          className="min-[300px]:h-10 h-8 gap-2 min-[300px]:gap-4  rounded-3xl text-black bg-white border border-transparent hover:border-black active:border-black  flex items-center justify-center  p-2 min-[300px]:p-3.5 "
          onClick={() => navigate("/visitors")}
        >
          <IoArrowBack /> <span>Back</span>
        </button>

        <button
          className="flex items-center justify-center w-8 h-8 text-black bg-white border border-transparent rounded-full min-[300px]:w-10 min-[300px]:h-10 hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>
      <Container />
    </>
  );
}

export default Dashboard;
