import { useContext } from "react";
import { BsStars } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { GoGear } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import { AppLayoutContext } from "../pages/AppLayout";
import { GrInstall } from "react-icons/gr";
import { PiBombLight } from "react-icons/pi";

function MainNav() {
  const { setSidebarOpen } = useContext(AppLayoutContext);

  return (
    <nav>
      <ul className="flex flex-col gap-4 pl-0 w-full text-xl list-none">
        <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/dashboard"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <RxDashboard />
            <span> Dashboard</span>
          </NavLink>
        </li>
        <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/visitors"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <FiUsers />
            <span>Visitors </span>
          </NavLink>
        </li>
        <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/workflows"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <BsStars />
            <span>Workflows</span>
          </NavLink>
        </li>
        {/* <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/instalation"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <GrInstall />
            <span>Instalation</span>
          </NavLink>
        </li> */}

        <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/settings"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <GoGear />
            <span>Settings</span>
          </NavLink>
        </li>

        {/* <li className="flex justify-center items-center h-12 text-black bg-white rounded-lg hover:bg-black hover:text-white">
          <NavLink
            to="/popup"
            className="flex gap-2 items-center pl-5 w-full h-11"
            onClick={() => setSidebarOpen(false)}
          >
            <PiBombLight />
            <span>Popup</span>
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
}

export default MainNav;
