import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "../components/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/card";
import { toast } from "react-hot-toast";
import { WorkflowContext } from "./WorkflowContextProvider";

import Step1 from "./WorkflowSteps/Step1";
import Step2 from "./WorkflowSteps/Step2";
import Step3 from "./WorkflowSteps/Step3";
import Step4 from "./WorkflowSteps/Step4";
import Step5 from "./WorkflowSteps/Step5";
import WebhookConfig from "./WorkflowSteps/WebhookConfig";
import StepSlack from "./WorkflowSteps/StepSlack";
import StepDiscord from "./WorkflowSteps/StepDiscord";

import apiWorkflowsFields from "./WorkflowApis/apiWorkflowsFields";
import apiExistingWorkflow from "./WorkflowApis/apiExistingWorkflow";
import apiDiscordData from "./WorkflowApis/apiDiscordData";

export default function WorkflowPopup({ handleCancel, workflowId = null }) {
  const { createWorkflow, updateWorkflow } = useContext(WorkflowContext);
  const [workflowsFields, setWorkflowsFields] = useState([]);
  const [step, setStep] = useState(1);
  const [workflowData, setWorkflowData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialWorkflowData, setInitialWorkflowData] = useState(null);
  const cardRef = useRef(null);
  const step3Ref = useRef();
  const isEditMode = workflowId !== null;

  const validateWebhookConfig = () => {
    const isPopup = workflowData.action.type === "popup";

    if (isPopup) {
      if (workflowData.action?.notification?.url?.trim() === "") {
        toast.error("Please enter a webhook URL.");
        return false;
      }
      if (workflowData.action?.notification?.method?.trim() === "") {
        toast.error("Please select a method.");
        return false;
      }
    } else {
      if (workflowData.action?.url?.trim() === "") {
        toast.error("Please enter a webhook URL.");
        return false;
      }
      if (workflowData.action?.method?.trim() === "") {
        toast.error("Please select a method.");
        return false;
      }
    }

    try {
      if (workflowData.action.type === "popup") {
        JSON.parse(
          typeof workflowData.action.notification.payload === "string"
            ? workflowData.action.notification.payload
            : JSON.stringify(workflowData.action.notification.payload)
        );
      } else {
        JSON.parse(
          typeof workflowData.action.payload === "string"
            ? workflowData.action.payload
            : JSON.stringify(workflowData.action.payload)
        );
      }
    } catch (error) {
      toast.error("Invalid JSON payload. Please correct it before proceeding.");
      return false;
    }
    return true;
  };

  const validateSlackConfig = () => {
    const isPopup = workflowData.action.type === "popup";
    if (isPopup) {
      if (!workflowData.action?.notification?.channel_id) {
        toast.error("Please select a Slack channel or user.");
        return false;
      }
      if (!workflowData.action?.notification?.message?.trim()) {
        toast.error("Please enter a message for Slack.");
        return false;
      }
    } else {
      if (!workflowData.action?.channel_id) {
        toast.error("Please select a Slack channel or user.");
        return false;
      }
      if (!workflowData.action?.message?.trim()) {
        toast.error("Please select a Slack channel or user.");
        return false;
      }
    }

    return true;
  };

  const validateDiscordConfig = () => {
    const isPopup = workflowData.action.type === "popup";
    if (isPopup) {
      if (!workflowData.action?.notification?.channel_id) {
        toast.error("Please select a Discord channel or user.");
        return false;
      }
      if (!workflowData.action?.notification?.message?.trim()) {
        toast.error("Please enter a message for Discord.");
        return false;
      }
    } else {
      if (!workflowData.action?.channel_id) {
        toast.error("Please select a Discord channel or user.");
        return false;
      }
      if (!workflowData.action?.message?.trim()) {
        toast.error("Please select a Discord channel or user.");
        return false;
      }
    }

    return true;
  };

  const isStepValid = () => {
    if (!workflowData) return false;

    if (step === 1) {
      return workflowData.name && workflowData.name.trim() !== "";
    }
    // step 2 doesn't need validation and step 3 validation is done in the next step function
    // step 4 doesn't need validation
    if (step === 5) {
      if (
        workflowData.action.type === "slack_notification" ||
        workflowData.action?.notification?.type === "slack_notification"
      ) {
        return validateSlackConfig();
      }
      if (
        workflowData.action.type === "discord_notification" ||
        workflowData.action?.notification?.type === "discord_notification"
      ) {
        return validateDiscordConfig();
      }
      if (
        workflowData.action.type === "webhook" ||
        workflowData.action?.notification?.type === "webhook"
      ) {
        return validateWebhookConfig();
      }
      return true; // If no specific action type, consider it valid
    }

    return true;
  };

  const hasWorkflowChanged = () => {
    return JSON.stringify(workflowData) !== JSON.stringify(initialWorkflowData);
  };

  const handleSubmit = () => {
    try {
      const formattedWorkflowData = {
        name: workflowData.name,
        pages: workflowData.pages,
        conditions:
          workflowData.conditions &&
          Object.keys(workflowData.conditions).length > 0
            ? formatConditions(workflowData.conditions)
            : { conditions: [], logic: "and" },
        action: {
          ...workflowData.action,
        },
      };

      if (isEditMode) {
        if (hasWorkflowChanged()) {
          updateWorkflow(workflowId, formattedWorkflowData);
        } else {
          console.log("No changes detected. Closing popup without updating.");
        }
      } else {
        console.log(formattedWorkflowData);
        createWorkflow(formattedWorkflowData);
      }

      handleCancel(false);
    } catch (error) {
      toast.error("Error processing workflow data. Please check your inputs.");
    }
  };

  const formatConditions = (conditions) => {
    const formatDate = (dateString) => {
      if (!dateString) return dateString;
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return dateString; // Return original string if it's not a valid date
      return date.toISOString().replace("Z", "+00:00");
    };

    const formatCondition = (condition) => ({
      ...condition,
      value:
        workflowsFields[condition.field] === "date"
          ? formatDate(condition.value)
          : condition.value,
    });

    if (Array.isArray(conditions.conditions[0])) {
      // Multiple groups (OR condition)
      return {
        ...conditions,
        conditions: conditions.conditions.map((group) =>
          group.map(formatCondition)
        ),
      };
    } else {
      // Single group (AND condition)
      return {
        ...conditions,
        conditions: conditions.conditions.map(formatCondition),
      };
    }
  };

  const nextStep = () => {
    // Validate if it's Step 3
    if (step === 3 && step3Ref.current) {
      const isStep3Valid = step3Ref.current.validateStep();
      if (!isStep3Valid) {
        toast.error(
          "Please fill in all fields for each condition or remove incomplete rows."
        );
        return;
      }
    }

    if (isStepValid()) {
      if (step === 5) {
        handleSubmit(); // Only call handleSubmit if isStepValid is true
      } else {
        setStep((prev) => Math.min(prev + 1, 5));
      }
    } else {
      toast.error("Please fill in all required fields.");
    }
  };

  const prevStep = () => {
    setStep((prev) => Math.max(prev - 1, 0));
  };

  const handleSkipStep3 = () => {
    setWorkflowData((prevData) => ({
      ...prevData,
      conditions: {
        conditions: [],
        logic: "and",
      },
    }));
    nextStep();
  };

  useEffect(() => {
    const fetchWorkflowsFields = async () => {
      try {
        const data = await apiWorkflowsFields();
        setWorkflowsFields(data.fields);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch workflow fields");
      }
    };

    fetchWorkflowsFields();
  }, []);

  useEffect(() => {
    const fetchWorkflowData = async () => {
      setIsLoading(true);
      if (workflowId) {
        try {
          const data = await apiExistingWorkflow(workflowId);
          setWorkflowData(data);
          setInitialWorkflowData(JSON.parse(JSON.stringify(data))); // Deep copy
        } catch (error) {
          console.error("Error fetching workflow data:", error);
          toast.error("Failed to fetch workflow data");
        }
      } else {
        const defaultData = getDefaultWorkflowStructure();
        setWorkflowData(defaultData);
        setInitialWorkflowData(JSON.parse(JSON.stringify(defaultData))); // Deep copy
      }
      setIsLoading(false);
    };

    fetchWorkflowData();
  }, [workflowId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex fixed inset-0 justify-center items-center p-4 backdrop-blur-sm bg-gray-500/30">
      {workflowData && (
        <Card className="w-full max-w-[500px]" ref={cardRef}>
          <CardHeader>
            <CardTitle className="text-2xl font-bold">
              {isEditMode ? "Update Workflow" : "New Workflow"}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {step === 1 && (
              <Step1
                workflowData={workflowData}
                setWorkflowData={setWorkflowData}
                isEditMode={isEditMode}
              />
            )}
            {step === 2 && (
              <Step2
                workflowData={workflowData}
                setWorkflowData={setWorkflowData}
              />
            )}
            {step === 3 && (
              <Step3
                ref={step3Ref}
                workflowData={workflowData}
                setWorkflowData={setWorkflowData}
                workflowsFields={workflowsFields}
                onSkip={handleSkipStep3}
                isEditMode={isEditMode}
              />
            )}
            {step === 4 && (
              <Step4
                workflowData={workflowData}
                setWorkflowData={setWorkflowData}
              />
            )}
            {step === 5 &&
              (workflowData.action?.notification?.type ===
                "slack_notification" ||
                workflowData.action.type === "slack_notification") && (
                <StepSlack
                  workflowData={workflowData}
                  setWorkflowData={setWorkflowData}
                />
              )}
            {step === 5 &&
              (workflowData.action?.notification?.type ===
                "discord_notification" ||
                workflowData.action.type === "discord_notification") && (
                <StepDiscord
                  workflowData={workflowData}
                  setWorkflowData={setWorkflowData}
                />
              )}
            {step === 5 &&
              (workflowData.action?.notification?.type === "webhook" ||
                workflowData.action.type === "webhook") && (
                <WebhookConfig
                  workflowData={workflowData}
                  setWorkflowData={setWorkflowData}
                />
              )}
            <div className="flex justify-between">
              <Button variant="outline" onClick={() => handleCancel(false)}>
                Cancel
              </Button>

              <div className="flex gap-4">
                {step !== 0 && (
                  <Button
                    variant="outline"
                    onClick={prevStep}
                    disabled={step === 0}
                  >
                    Previous
                  </Button>
                )}
                <Button onClick={nextStep}>
                  {(step === 4 &&
                    workflowData.action.type === "slack_notification") ||
                  (step === 5 && workflowData.action.type === "webhook") ||
                  (step === 4 &&
                    workflowData.action.type === "discord_notification")
                    ? isEditMode
                      ? "Update"
                      : "Create"
                    : "Next Step"}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

function getDefaultWorkflowStructure() {
  return {
    name: "",
    pages: [],
    conditions: {
      conditions: [],
      logic: "and",
    },
    action: {
      type: "webhook",
      method: "GET",
      payload: "",
      url: "",
    },
  };
}
