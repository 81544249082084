const apiInvitationToOrg = async (email) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");
  const bodyexample = { email: email };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${org_id}/invite`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyexample),
      }
    );

    if (!response.ok) {
      throw new Error("Getting invitation link failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error Getting invitation link:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiInvitationToOrg;
