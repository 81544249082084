import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { Label } from "../components/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";

import { AppLayoutContext } from "./AppLayout";
import { RxHamburgerMenu } from "react-icons/rx";
import apiUpdateLogedUser from "../Signup/apiUpdateLogedUser";
import toast from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import apiInvitationToOrg from "../Signup/apiInvitationToOrg";
import apiDeclineInvite from "../Signup/apiDeclineInvite";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableHead,
} from "../components/table";
import apiUpdateOrgName from "../Signup/apiUpdateOrgName";

export default function SettingsPage() {
  const [teammates, setTeammates] = useState([]);
  const [newTeammate, setNewTeammate] = useState("");

  const [responsiveClass, setResponsiveClass] = useState("h-1300");

  const { setSidebarOpen } = useContext(AppLayoutContext);

  const addTeammate = () => {
    if (newTeammate) {
      setTeammates([...teammates, newTeammate]);
      setNewTeammate("");
    }
  };

  const [name, setName] = useState(localStorage.getItem("agentName") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const orgid = localStorage.getItem("orgid");
  const userid = localStorage.getItem("userid");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [orgName, setOrgName] = useState(localStorage.getItem("orgName") || "");

  const [initialName, setInitialName] = useState(name);
  const [initialEmail, setInitialEmail] = useState(email);

  const handleNameChange = useCallback((e) => setName(e.target.value), []);
  const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
    setShowRepeatPassword(e.target.value.length > 0);
  }, []);
  const handleRepeatPasswordChange = useCallback(
    (e) => {
      setRepeatPassword(e.target.value);
      setPasswordError(
        e.target.value !== password ? "Passwords do not match" : ""
      );
    },
    [password]
  );

  const handleSubmit = async () => {
    if (password !== repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const payload = {};
    let hasChanges = false;

    if (name !== initialName) {
      payload.name = name;
      hasChanges = true;
    }
    if (email !== initialEmail) {
      payload.email = email;
      hasChanges = true;
    }
    if (password) {
      payload.password = password;
      hasChanges = true;
    }

    if (!hasChanges) {
      toast.error("No changes detected");
      return;
    }

    // Only make API call if there are changes
    try {
      await apiUpdateLogedUser(payload);

      // Update local storage only after successful API call
      if (payload.name) localStorage.setItem("agentName", payload.name);
      if (payload.email) localStorage.setItem("email", payload.email);

      // Update initial values
      setInitialName(name);
      setInitialEmail(email);

      // Clear password fields
      setPassword("");
      setRepeatPassword("");
      setShowRepeatPassword(false);

      toast.success("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.dispatchEvent(new Event("storage"));
  };

  const handleSlack = () => {
    const org_id = localStorage.getItem("orgid");

    window.location.href = `${process.env.REACT_APP_API_URL}/integrations/slack/integrate?org_id=${org_id}`;
  };

  const handleDiscord = () => {
    const org_id = localStorage.getItem("orgid");

    window.location.href = `${process.env.REACT_APP_API_URL}/integrations/discord/integrate?org_id=${org_id}`;
  };

  const handleTabChange = (value) => {
    if (value === "popup") {
      setResponsiveClass("h-1300");
    } else {
      setResponsiveClass("");
    }
  };

  const isIntegrated = localStorage.getItem("slackIntegrated") === "true";
  const discordIntegrated =
    localStorage.getItem("discordIntegrated") === "true";

  const org_id = localStorage.getItem("orgid");
  const agent_id = localStorage.getItem("userid");
  const isAdmin = localStorage.getItem("isOwner");

  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteLinks, setInviteLinks] = useState([]);

  const handleGenerateLink = async () => {
    if (!/\S+@\S+\.\S+/.test(inviteEmail)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const response = await apiInvitationToOrg(inviteEmail);
      const link = response.invitation_id;
      // Add the new invite link to the state
      setInviteLinks((prevLinks) => [
        ...prevLinks,
        { email: inviteEmail, link },
      ]);
      setInviteEmail(""); // Clear the input field after submission
    } catch (error) {
      console.error("Error generating invitation link:", error);
      toast.error("Failed to generate invitation link. Please try again.");
    }
  };

  const handleDeclineInvite = async (linkToDelete) => {
    try {
      await apiDeclineInvite(linkToDelete); // Call the API to decline the invite
      // Remove the declined invite from the state
      setInviteLinks((prevLinks) =>
        prevLinks.filter((item) => item.link !== linkToDelete)
      );
      toast.success("Invitation declined successfully!");
    } catch (error) {
      console.error(
        "Error declining invitation (user already accepted or declined):",
        error
      );
      setInviteLinks((prevLinks) =>
        prevLinks.filter((item) => item.link !== linkToDelete)
      );
    }
  };

  const handleUpdateOrgName = async () => {
    try {
      await apiUpdateOrgName(orgName);
      toast.success("Organization name updated successfully!");
    } catch (error) {
      console.error("Error updating organization name:", error);
      toast.error("Failed to update organization name. Please try again.");
    }
  };

  return (
    <div className="h-full">
      <div className="flex justify-end pb-2">
        <button
          className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>

      <Tabs
        defaultValue="account"
        className={"w-full " + responsiveClass}
        onValueChange={handleTabChange}
      >
        <TabsList className="grid grid-cols-2 w-full h-auto bg-gray-50 min-[420px]:grid-cols-4">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="organization">Organization</TabsTrigger>
          <TabsTrigger value="popup">Popup</TabsTrigger>
          <TabsTrigger value="Integrations">Integrations</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Card>
            <CardHeader>
              <CardTitle>Account Settings</CardTitle>
              <CardDescription>
                Manage your account details here.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter your full name"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter new password"
                />
              </div>
              {showRepeatPassword && (
                <div className="space-y-2">
                  <Label htmlFor="repeatPassword">Repeat Password</Label>
                  <Input
                    id="repeatPassword"
                    type="password"
                    value={repeatPassword}
                    onChange={handleRepeatPasswordChange}
                    placeholder="Repeat new password"
                  />
                  {passwordError && (
                    <p className="text-sm text-red-500">{passwordError}</p>
                  )}
                </div>
              )}
            </CardContent>
            <CardFooter>
              <Button onClick={handleSubmit}>Save Changes</Button>
            </CardFooter>
          </Card>

          <Button
            className="mt-10 w-full max-w-xs"
            variant="destructive"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </TabsContent>
        <TabsContent value="organization">
          <Card>
            <CardHeader>
              <CardTitle>Organization Settings</CardTitle>
              <CardDescription>
                Manage your organization and team.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="companyName">Company Name</Label>
                <Input
                  id="companyName"
                  placeholder={orgName}
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button onClick={handleUpdateOrgName}>Save Changes</Button>
            </CardFooter>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              <CardTitle>Invitation Link to Organization</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="inviteEmail">Email</Label>
                <Input
                  id="inviteEmail"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  placeholder="Enter email to invite"
                />
              </div>
              <Button onClick={handleGenerateLink}>Generate</Button>

              {/* Conditional rendering of the table */}
              {inviteLinks.length > 0 && (
                <>
                  <h3 className="text-lg font-semibold">Invitation Links</h3>
                  <div className="rounded-lg border border-black">
                    <Table className="bg-white rounded-lg">
                      <TableHeader>
                        <TableRow>
                          <TableHead>Email</TableHead>
                          <TableHead>Link</TableHead>
                          <TableHead className="text-right">Action</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {inviteLinks.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{`${process.env.REACT_APP_API_URL}/invitation/${item.link}`}</TableCell>
                            <TableCell className="text-right">
                              <Button
                                variant="destructive"
                                onClick={() => handleDeclineInvite(item.link)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="popup" className="p-0 h-full bg-white border-none">
          <div className="flex overflow-auto justify-start h-full">
            <div className="w-full h-auto">
              <div className="relative w-full h-full">
                <iframe
                  src={`https://f2f-89200.bubbleapps.io/popup_config?orgId=${org_id}&agentId=${agent_id}&admin=${isAdmin}&debug_mode=true`}
                  className="absolute inset-0 w-full h-full"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="Integrations">
          <Card>
            <CardHeader>
              <CardTitle>Slack Integration</CardTitle>
              <CardDescription>
                Manage your Slack integration here.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              {isIntegrated ? (
                <div className="flex flex-col gap-4 items-start space-x-2 text-green-600">
                  <div className="flex items-center space-x-2">
                    <FaCheckCircle className="w-5 h-5" />
                    <Label>Your organization is connected to Slack.</Label>
                  </div>

                  <Button className="text-wrap" onClick={handleSlack}>
                    Click here to integrate Slack on other channel.
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center space-x-2 text-red-500">
                    <FaTimesCircle className="w-5 h-5" />
                    <Label>Your organization is not connected to Slack.</Label>
                  </div>

                  <Button className="text-wrap" onClick={handleSlack}>
                    Click here to integrate Slack
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              <CardTitle>Discord Integration</CardTitle>
              <CardDescription>
                Manage your Discord integration here.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              {discordIntegrated ? (
                <div className="flex flex-col gap-4 items-start space-x-2 text-green-600">
                  <div className="flex items-center space-x-2">
                    <FaCheckCircle className="w-5 h-5" />
                    <Label>Your organization is connected to Discord.</Label>
                  </div>

                  <Button className="text-wrap" onClick={handleDiscord}>
                    Click here to integrate Discord on other channel.
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center space-x-2 text-red-500">
                    <FaTimesCircle className="w-5 h-5" />
                    <Label>
                      Your organization is not connected to Discord.
                    </Label>
                  </div>

                  <Button className="text-wrap" onClick={handleDiscord}>
                    Click here to integrate Discord
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
