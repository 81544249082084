import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";
import { Input } from "../components/input";
import { Button } from "../components/button";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import toast from "react-hot-toast";

import apiVisitorIdentity from "./VisitorsApis/apiVisitorIdentity";

export default function VisitorIdentityCard({ visitor_id, initialData = {} }) {
  const [identityPairs, setIdentityPairs] = useState(() =>
    Object.keys(initialData).length > 0 ? initialData : { "": "" }
  );
  const [deletedInitialKeys, setDeletedInitialKeys] = useState([]);
  const [isInitialDataPopulated, setIsInitialDataPopulated] = useState(false);

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      setIdentityPairs(initialData);
      setIsInitialDataPopulated(true);
    }
  }, [initialData]);

  const addNewPair = useCallback(() => {
    setIdentityPairs((prev) => ({ ...prev, "": "" }));
  }, []);

  const updatePair = useCallback(
    (key, newKey, value) => {
      setIdentityPairs((prev) => {
        const updatedPairs = { ...prev };
        if (key !== newKey) {
          delete updatedPairs[key];
        }
        updatedPairs[newKey] = value;
        return updatedPairs;
      });

      if (deletedInitialKeys.includes(newKey)) {
        setDeletedInitialKeys((prevKeys) =>
          prevKeys.filter((k) => k !== newKey)
        );
      }
    },
    [deletedInitialKeys]
  );

  const deletePair = useCallback(
    (keyToDelete) => {
      setIdentityPairs((prev) => {
        const updatedPairs = { ...prev };
        delete updatedPairs[keyToDelete];
        return Object.keys(updatedPairs).length === 0 && !isInitialDataPopulated
          ? { "": "" }
          : updatedPairs;
      });

      if (keyToDelete in initialData) {
        setDeletedInitialKeys((prevKeys) => [...prevKeys, keyToDelete]);
      }
    },
    [initialData, isInitialDataPopulated]
  );

  const isDataValid = useCallback(() => {
    return Object.entries(identityPairs).every(
      ([key, value]) => key.trim() !== "" && value.trim() !== ""
    );
  }, [identityPairs]);

  const isDataChanged = useCallback(() => {
    const currentKeys = Object.keys(identityPairs);
    const initialKeys = Object.keys(initialData);

    if (currentKeys.length !== initialKeys.length) {
      return true;
    }

    return (
      currentKeys.some((key) => {
        return !(key in initialData) || identityPairs[key] !== initialData[key];
      }) || deletedInitialKeys.length > 0
    );
  }, [identityPairs, initialData, deletedInitialKeys]);

  const createIdentity = useCallback(async () => {
    if (!isDataValid()) {
      toast.error("Please provide both key and value for all rows.");
      return;
    }

    if (!isDataChanged()) {
      toast.error(
        "No changes detected. Please modify the data before submitting."
      );
      return;
    }

    let finalPairs = {};
    let keysToDelete = [...deletedInitialKeys];

    // Process current identity pairs
    Object.entries(identityPairs).forEach(([key, value]) => {
      if (key.trim() !== "" && value.trim() !== "") {
        finalPairs[key] = value;
        // If this key is not in initialData, it might be a renamed key
        if (!(key in initialData)) {
          // Find if there's a key in initialData that's not in current identityPairs
          const possibleOldKey = Object.keys(initialData).find(
            (initialKey) =>
              !(initialKey in identityPairs) &&
              !keysToDelete.includes(initialKey)
          );
          if (possibleOldKey) {
            keysToDelete.push(possibleOldKey);
          }
        }
      }
    });

    // Add all keys to be deleted with blank values
    keysToDelete.forEach((key) => {
      if (!(key in finalPairs)) {
        finalPairs[key] = "";
      }
    });

    try {
      await apiVisitorIdentity(visitor_id, finalPairs);
      window.location.reload(); // Reload the page to fetch the latest data
    } catch (error) {
      console.error("Error updating identity:", error);
      // Handle error (e.g., show error toast)
    }
  }, [
    identityPairs,
    deletedInitialKeys,
    visitor_id,
    isDataChanged,
    isDataValid,
    initialData,
  ]);

  const pairCount = Object.keys(identityPairs).length;

  return (
    <Card className="mx-auto w-full max-w-2xl rounded-lg border-0 shadow-none">
      <CardHeader className="pb-4">
        <CardTitle className="text-xl">Visitor Identity</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {Object.entries(identityPairs).map(([key, value], index) => (
          <div
            key={index}
            className="flex flex-col items-start space-y-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2"
          >
            <div className="flex-1 w-full sm:w-auto">
              <Input
                placeholder="Key"
                value={key}
                onChange={(e) => updatePair(key, e.target.value, value)}
                className="w-full"
              />
            </div>
            <span className="hidden px-2 text-2xl font-bold sm:inline">=</span>
            <div className="flex-1 w-full sm:w-auto">
              <Input
                placeholder="Value"
                value={value}
                onChange={(e) => updatePair(key, key, e.target.value)}
                className="w-full"
              />
            </div>
            {(isInitialDataPopulated ||
              Object.keys(identityPairs).length > 1) && (
              <Button
                variant="ghost"
                onClick={() => deletePair(key)}
                className="mt-2 w-full text-red-500 hover:text-red-700 sm:mt-0 sm:w-auto"
              >
                <AiOutlineClose className="mr-2 w-4 h-4 sm:mr-0" />
                <span className="sm:hidden">Delete row above</span>
              </Button>
            )}
          </div>
        ))}
        <Button onClick={addNewPair} variant="outline" className="mt-4 w-full">
          <AiOutlinePlus className="mr-2 w-4 h-4" /> Add
        </Button>
      </CardContent>
      <CardFooter className="pt-2">
        <Button onClick={createIdentity} className="w-full">
          {Object.keys(identityPairs).length === 0 &&
          deletedInitialKeys.length > 0
            ? "Clear Visitor Identity"
            : isInitialDataPopulated
            ? "Update Identity"
            : "Link Identity to Visitor"}
        </Button>
      </CardFooter>
    </Card>
  );
}
