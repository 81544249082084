import React from "react";
import LoginForm from "../ui/LoginForm";
import SignUpForm from "../ui/SignUpForm";

const LandingPage = () => {
  return (
    <div>
      {/* <LoginButton /> */}
      <SignUpForm>
        <LoginForm />
      </SignUpForm>
    </div>
  );
};

export default LandingPage;
