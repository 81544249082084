const apiRegister = async (registerData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerData),
      }
    );

    const data = await response.json();
    console.log("Register successful:", data);

    return { success: true, data }; // Return success and data
  } catch (error) {
    console.error("Error during register:", error);
    return { success: false, error }; // Return failure and error message
  }
};

export default apiRegister;
