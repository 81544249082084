import React, { useEffect, useState } from "react";
import { RadioGroup, RadioGroupItem } from "../../components/radio-group";
import { Label } from "../../components/label";

import apiOrganization from "../../Dashborad/apiOrganization";

export default function Step4({ workflowData, setWorkflowData }) {
  const isIntegrated = localStorage.getItem("slackIntegrated") === "true";
  const discordIntegrated =
    localStorage.getItem("discordIntegrated") === "true";

  const isPopup = workflowData.action.type === "popup";

  useEffect(() => {
    const initalPopupNotificatonType = () => {
      if (workflowData.action.type === "popup") {
        if (!workflowData.action?.notification?.type) {
          setWorkflowData((prev) => ({
            ...prev,
            action: { ...prev.action, notification: { type: "webhook" } },
          }));
        }
      }
    };
    initalPopupNotificatonType();
  }, [workflowData, setWorkflowData]);

  const handleValueChange = (value) => {
    if (isPopup) {
      setWorkflowData((prev) => ({
        ...prev,
        action: { ...prev.action, notification: { type: value } },
      }));
    } else {
      setWorkflowData((prev) => ({
        ...prev,
        action: { ...prev.action, type: value },
      }));
    }
    console.log(workflowData);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">
        How would you like to get notified?
      </h2>
      <RadioGroup
        value={
          isPopup
            ? workflowData.action?.notification?.type || "webhook"
            : workflowData.action.type
        }
        onValueChange={handleValueChange}
      >
        <div
          className={`flex items-center p-4 border rounded-md cursor-pointer transition-all ${
            isPopup
              ? workflowData.action?.notification?.type === "webhook"
                ? "border-black"
                : "border-gray-200 hover:border-gray-300"
              : workflowData.action.type === "webhook"
              ? "border-black"
              : "border-gray-200 hover:border-gray-300"
          }`}
          onClick={() => handleValueChange("webhook")}
        >
          <RadioGroupItem value="webhook" id="webhook" className="hidden" />
          <div>
            <Label htmlFor="webhook" className="font-semibold">
              Webhook
            </Label>
            <p className="text-sm text-gray-500">
              We'll give you a webhook which you can setup anywhere
            </p>
          </div>
        </div>

        <div
          className={`flex items-center p-4 border rounded-md ${
            isIntegrated
              ? "cursor-pointer"
              : "text-gray-500 bg-gray-50 cursor-not-allowed"
          } transition-all ${
            isPopup
              ? workflowData.action?.notification?.type === "slack_notification"
                ? "border-black"
                : "border-gray-200 hover:border-gray-300"
              : workflowData.action.type === "slack_notification"
              ? "border-black"
              : "border-gray-200 hover:border-gray-300"
          }`}
          onClick={() =>
            isIntegrated && handleValueChange("slack_notification")
          }
        >
          <RadioGroupItem
            value="slack_notification"
            id="slack_notification"
            className="hidden"
            disabled={!isIntegrated}
          />
          <div className="flex justify-between items-center w-full">
            <div>
              <Label htmlFor="slack_notification" className="font-semibold">
                Slack {!isIntegrated && "(Configure it in settings)"}
              </Label>
              <p className="text-sm text-gray-500">
                Get notified in your favorite Slack channel
              </p>
            </div>
            <img
              src="/logo/slackLogo.png?height=27&width=27"
              alt="Slack logo"
              className="w-8 h-8 rounded-lg border border-green-200"
            />
          </div>
        </div>
        {/* ---------------------------------------------------------------------------------------------------------------------------- */}
        <div
          className={`flex items-center p-4 border rounded-md ${
            discordIntegrated
              ? "cursor-pointer"
              : "text-gray-500 bg-gray-50 cursor-not-allowed"
          } transition-all ${
            isPopup
              ? workflowData.action?.notification?.type ===
                "discord_notification"
                ? "border-black"
                : "border-gray-200 hover:border-gray-300"
              : workflowData.action.type === "discord_notification"
              ? "border-black"
              : "border-gray-200 hover:border-gray-300"
          }`}
          onClick={() =>
            discordIntegrated && handleValueChange("discord_notification")
          }
        >
          <RadioGroupItem
            value="discord_notification"
            id="discord_notification"
            className="hidden"
            disabled={!discordIntegrated}
          />
          <div className="flex justify-between items-center w-full">
            <div>
              <Label htmlFor="discord_notification" className="font-semibold">
                Discord {!discordIntegrated && "(Configure it in settings)"}
              </Label>
              <p className="text-sm text-gray-500">
                Send notifications to discord
              </p>
            </div>
            <img
              src="/logo/discordLogo.png?height=27&width=27"
              alt="Discord logo"
              className="w-8 h-8 rounded-lg"
            />
          </div>
        </div>
      </RadioGroup>
    </div>
  );
}
