import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/table";
import ConfirmDeleteCard from "../ui/ConfirmDeleteCard";
import apiWorkflowActivate from "./WorkflowApis/apiWorkflowActivate";
import apiWorkflowDeactivate from "./WorkflowApis/apiWorkflowDeactivate";
import Spinner from "../ui/Spinner";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../components/dropdown-menu";
import { Button } from "../components/button";
import { BsThreeDotsVertical, BsPencil } from "react-icons/bs";
import { WorkflowContext } from "./WorkflowContextProvider";
import WorkflowPopup from "./WorkflowPopup";
import toast from "react-hot-toast";

const CustomSwitch = ({ isOn, workflowId, onToggle }) => {
  const [checked, setChecked] = useState(isOn);

  const handleToggle = async () => {
    setChecked(!checked);
    await onToggle(workflowId, !checked);
  };

  return (
    <button
      onClick={handleToggle}
      className={`relative inline-flex items-center h-7 rounded-full w-16 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
        checked ? "bg-black" : "bg-gray-200"
      }`}
    >
      <span
        className={`${
          checked ? "translate-x-10" : "translate-x-1"
        } inline-block w-5 h-5 transform bg-white rounded-full transition-transform`}
      />
      <span
        className={`absolute inset-0 flex items-center ${
          checked ? "justify-start pl-2" : "justify-end pr-2"
        } text-xs font-semibold ${checked ? "text-white" : "text-gray-900"}`}
      >
        {checked ? "ON" : "OFF"}
      </span>
    </button>
  );
};

export default function WorkflowsTable() {
  const { workflows, fetchWorkflows, isLoading, error } =
    useContext(WorkflowContext);
  const [editingWorkflowId, setEditingWorkflowId] = useState(null);

  const handleToggleSwitch = async (workflowId, isActive) => {
    console.log(workflowId, isActive);
    if (isActive) {
      await apiWorkflowDeactivate(workflowId);
      toast.success("Workflow deactivated.");
    } else {
      await apiWorkflowActivate(workflowId);
      toast.success("Workflow activated.");
    }
    // Fetch workflows again after the toggle action
    fetchWorkflows(); // Ensure this function is accessible in this scope
  };

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleEdit = (workflowId) => {
    setEditingWorkflowId(workflowId);
  };

  const handleClosePopup = () => {
    setEditingWorkflowId(null);
  };

  return (
    <div className="overflow-auto w-full">
      <Table className="bg-white">
        <TableHeader>
          <TableRow>
            <TableHead className="w-[40%] min-w-[200px]">WORKFLOW ID</TableHead>
            <TableHead className="w-[40%] min-w-[200px]">NAME</TableHead>
            <TableHead className="w-[20%] min-w-[150px]">TYPE</TableHead>
            <TableHead className="w-[20%] min-w-[100px]">
              TIMES TRIGGERED
            </TableHead>
            <TableHead className="w-[10%] min-w-[80px]">STATUS</TableHead>
            <TableHead className="w-[10%] min-w-[80px]"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {workflows.map((workflow) => (
            <TableRow key={workflow._id}>
              <TableCell className="font-medium">{workflow._id}</TableCell>
              <TableCell className="font-medium">{workflow.name}</TableCell>
              <TableCell>{workflow.action.type}</TableCell>
              <TableCell>{workflow.times_triggered}</TableCell>
              <TableCell>
                <CustomSwitch
                  isOn={workflow.active}
                  workflowId={workflow._id}
                  onToggle={() =>
                    handleToggleSwitch(workflow._id, workflow.active)
                  }
                />
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0 w-8 h-8">
                      <span className="sr-only">Open menu</span>
                      <BsThreeDotsVertical className="w-4 h-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => handleEdit(workflow._id)}>
                      <BsPencil className="mr-2 w-4 h-4" />
                      <span className="text-black">Edit</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="p-0"
                      onSelect={(e) => e.preventDefault()}
                    >
                      <ConfirmDeleteCard
                        workflowName={workflow.name}
                        workflowId={workflow._id}
                      />
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingWorkflowId && (
        <WorkflowPopup
          workflowId={editingWorkflowId}
          handleCancel={handleClosePopup}
        />
      )}
    </div>
  );
}
