import React, { useState, useCallback } from "react";
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
} from "@daily-co/daily-react";

import Tile from "./Tile";

export default function Call() {
  const remoteParticipantIds = useParticipantIds({ filter: "remote" });

  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1;

  const renderCallScreen = () => (
    <div className="flex flex-row">
      {localSessionId && <Tile id={localSessionId} isLocal isAlone={isAlone} />}
      {remoteParticipantIds.length > 0 ? (
        <>
          {remoteParticipantIds.map((id) => (
            <Tile key={id} id={id} />
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );

  return renderCallScreen();
}
