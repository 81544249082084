import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../components/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";
import { FaUsers, FaClock, FaVideo, FaEye } from "react-icons/fa";
import { AppLayoutContext } from "../pages/AppLayout";
import { RxHamburgerMenu } from "react-icons/rx";
import apiOrganization from "./apiOrganization";
import Spinner from "../ui/Spinner";

function Metric({ label, value }) {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-medium text-muted-foreground">{label}</p>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
}

function formatTime(seconds) {
  const h = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const m = Math.floor((seconds % 3600) / 60); // remaining minutes
  const s = seconds % 60; // remaining seconds

  if (h > 0) {
    return `${h} h ${m} min`;
  } else if (m > 0) {
    return `${m} min`;
  } else {
    return `${s} sec`;
  }
}

function calculateAvgSessionTime(totalTimeInSeconds = 0, numOfSessions = 0) {
  if (numOfSessions === 0) return "0 sec"; // Handle divide by zero
  const avgSeconds = Math.round(totalTimeInSeconds / numOfSessions); // Round to nearest whole number

  return formatTime(avgSeconds);
}

export default function Dashboard() {
  const { setSidebarOpen } = useContext(AppLayoutContext);

  const [isLoading, setIsLoading] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const logedUser = localStorage.getItem("userid");
  useEffect(() => {
    async function fetchOrgData() {
      try {
        setIsLoading(true);
        const data = await apiOrganization();
        setOrgData(data.organization);
        console.log(data.organization);
        localStorage.setItem("orgName", data.organization.name);
        // Check if the logged user is an owner
        const userMember = data.organization.members.find(
          (member) => member.user_id === logedUser
        );
        if (userMember && userMember.role === "owner") {
          localStorage.setItem("isOwner", "true");
        } else {
          localStorage.setItem("isOwner", "false");
        }

        if (data.organization?.integrations.slack) {
          localStorage.setItem("slackIntegrated", true);
        } else {
          localStorage.setItem("slackIntegrated", false);
        }
        if (data.organization?.integrations.discord) {
          localStorage.setItem("discordIntegrated", true);
        } else {
          localStorage.setItem("discordIntegrated", false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrgData();
  }, []);

  if (isLoading) return <Spinner />;
  else {
    return (
      <div className="text-black bg-gray-100 rounded-lg">
        <div className="flex justify-between">
          <h1 className="pointer-events-none mb-8 w-[250px] min-[610px]:w-[400px] text-4xl font-bold ">
            Customer Dashboard
          </h1>
          <button
            className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-black hover:border-black active:border-black lg:hidden"
            onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
          >
            <RxHamburgerMenu />
          </button>
        </div>

        <Tabs defaultValue="visitors" className="space-y-6">
          <TabsList className="grid grid-cols-2 gap-4 w-full h-auto bg-gray-100 md:grid-cols-4">
            <TabsTrigger
              value="visitors"
              className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
            >
              <FaUsers className="mb-2 w-6 h-6" />
              <span>Visitors</span>
            </TabsTrigger>
            <TabsTrigger
              value="sessions"
              className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
            >
              <FaClock className="mb-2 w-6 h-6" />
              <span>Sessions</span>
            </TabsTrigger>
            <TabsTrigger
              value="f2f"
              className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
            >
              <FaVideo className="mb-2 w-6 h-6" />
              <span>F2F</span>
            </TabsTrigger>
            <TabsTrigger
              value="stalking"
              className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
            >
              <FaEye className="mb-2 w-6 h-6" />
              <span>Stalking</span>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="visitors">
            <Card>
              <CardHeader>
                <CardTitle>Visitor Information</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <Metric
                    label="Number of Visitors"
                    value={orgData.total_visitors || "0"}
                  />
                  <Metric label="Identified Visitors" value="X" />
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="sessions">
            <Card>
              <CardHeader>
                <CardTitle>Session Information</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <Metric
                    label="Total Sessions"
                    value={orgData.total_browsing_sessions || "0"}
                  />
                  <Metric
                    label="Total Session Time"
                    value={formatTime(orgData.total_browsing_time || "0")}
                  />
                  <Metric
                    label="Avg. Session Time"
                    value={
                      calculateAvgSessionTime(
                        orgData.total_browsing_time,
                        orgData.total_browsing_sessions
                      ) || "0"
                    }
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="f2f">
            <Card>
              <CardHeader>
                <CardTitle>F2F Information</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <Metric
                    label="Total F2F Time"
                    value={formatTime(orgData.total_f2f_time || "0")}
                  />
                  <Metric
                    label="Total F2F Sessions"
                    value={orgData.total_f2f_sessions || "0"}
                  />
                  <Metric
                    label="Avg. F2F Session Time"
                    value={
                      calculateAvgSessionTime(
                        orgData.total_f2f_time,
                        orgData.total_f2f_sessions
                      ) || "0"
                    }
                  />
                  <Metric
                    label="Total F2F Attempts"
                    value={orgData.total_attempts_to_f2f || "0"}
                  />
                  <Metric
                    label="% F2F Calls Accepted"
                    value={
                      Math.floor(
                        orgData.total_f2f_sessions /
                          orgData.total_attempts_to_f2f
                      )
                        ? `${Math.floor(
                            orgData.total_f2f_sessions /
                              orgData.total_attempts_to_f2f
                          )} %`
                        : "0 %"
                    }
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="stalking">
            <Card>
              <CardHeader>
                <CardTitle>Stalking Information</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <Metric
                    label="Total Stalking Sessions"
                    value={orgData.total_stalking_sessions || "0"}
                  />
                  <Metric
                    label="Total Stalking Time"
                    value={formatTime(orgData.total_stalking_time || "0")}
                  />
                  <Metric
                    label="Avg. Stalking Time"
                    value={calculateAvgSessionTime(
                      orgData.total_stalking_time,
                      orgData.total_stalking_sessions
                    )}
                  />
                  <Metric label="% Sessions Stalked" value="X" />
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    );
  }
}
