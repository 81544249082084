import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiDeclineInvite from "../Signup/apiDeclineInvite";
import apiAcceptInvite from "../Signup/apiAcceptInvite";

export default function Invitation() {
  const navigate = useNavigate();
  const { inviteid } = useParams(); // Get inviteid from URL
  const [declined, setDeclined] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    const org_id = localStorage.getItem("orgid");
    if (org_id) {
      navigate("/dashboard");
    }
    if (!userId) {
      navigate(`/login?redirect=/invitation/${inviteid}`); // Redirect to login with current URL
    }
  }, [navigate, inviteid]); // Added inviteid to dependencies

  const handleAccept = async () => {
    // Call apiAcceptInvitation with inviteid
    const response = await apiAcceptInvite(inviteid);

    if (response) {
      navigate("/dashboard"); // Redirect to dashboard on success
    }
  };

  const handleDecline = async () => {
    setDeclined(true);
    if (inviteid) {
      await apiDeclineInvite(inviteid); // Call apiDeclineInvitation with inviteid
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <header className="flex items-center p-5 w-full bg-white shadow-md">
        <div className="flex gap-4 justify-center items-center mx-auto">
          <img src="/logo/logoSmaller.png" alt="logo" className="w-10" />
          <p className="text-3xl font-bold text-gray-800">Face2Face.io</p>
        </div>
      </header>
      {!declined ? (
        <div className="flex flex-col flex-grow justify-center items-center bg-gray-100">
          {inviteid ? (
            <div className="flex flex-col items-center mt-10">
              <p className="text-lg text-gray-700">
                You are invited to join the Organization.
              </p>
              <div className="flex gap-4 mt-4">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                  onClick={handleAccept}
                >
                  Accept
                </button>
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                  onClick={handleDecline}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <p className="mt-10 text-lg text-gray-700">
              You are not invited to any organization.
            </p>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <p className="mt-10 text-lg font-semibold">
            You need an invite to join a organization.
          </p>
        </div>
      )}
    </div>
  );
}
