import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/alert-dialog";
import { Button } from "../components/button";
import { Separator } from "../components/separator";
import { BsTrash } from "react-icons/bs";

import { useContext } from "react";
import { WorkflowContext } from "../Workflows/WorkflowContextProvider";

export default function ConfirmationWindow({ workflowName, workflowId }) {
  const { deleteWorkflow } = useContext(WorkflowContext);

  async function handleDelete() {
    await deleteWorkflow(workflowId);
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="ghost"
          className="px-2 py-1.5 h-auto font-normal w-full justify-start"
        >
          <BsTrash className="w-4 h-4 mr-2" />
          <span className="text-black">Delete</span>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="sm:max-w-[425px] p-0 overflow-hidden gap-y-0 bg-gray-50">
        <div>
          <AlertDialogHeader className="p-6 pb-6">
            <AlertDialogTitle className="text-xl font-semibold">
              Are you sure you want to delete this workflow:{" "}
              <span className="font-semibold ">{workflowName}</span>?
            </AlertDialogTitle>
          </AlertDialogHeader>
        </div>
        <Separator className="w-full" />
        <div className="p-6">
          <AlertDialogDescription className="text-sm text-red-500 text-muted-foreground">
            This action is irreversible.
          </AlertDialogDescription>
        </div>
        <Separator className="w-full" />
        <AlertDialogFooter className="flex justify-end p-4 space-x-2">
          <AlertDialogCancel className="m-0">Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="m-0 bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={handleDelete}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
