import React from "react";
import { Input } from "../../components/input";
import { Label } from "../../components/label";

export default function Step2({ workflowData, setWorkflowData }) {
  const handleInputChange = (e) => {
    setWorkflowData((prev) => ({ ...prev, pages: [e.target.value] }));
  };

  console.log(workflowData);
  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">
        Which page should this workflow trigger on?
      </h2>
      <div className="space-y-2">
        <Label htmlFor="page-url">Enter a page URL</Label>
        <Input
          id="page-url"
          placeholder="example: /pricing"
          value={workflowData?.pages[0] || ""}
          onChange={handleInputChange}
        />
        <p className="text-sm text-gray-500">
          *leave blank if you'd like this to work across the entire app*
        </p>
      </div>
    </div>
  );
}
