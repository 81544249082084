import Popup from "../ui/Popup";

export default function SignUpForm({ children }) {
  return (
    <div>
      <header className="flex items-start p-5 border-b-2">
        <div className="flex items-center justify-center gap-4 pl-6">
          <img src="/logo/logoSmaller.png" alt="logo" className="w-7" />
          <p className="text-2xl font-bold">Face2Face.io</p>
        </div>
      </header>
      {/* <Popup />  children here*/}
      {children}
    </div>
  );
}
