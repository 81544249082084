import { createContext, useEffect, useState, useRef } from "react";
import Sidebar from "../ui/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import apiCheckForCalling from "../Signup/apiCheckForCalling";
export const AppLayoutContext = createContext();

function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [declinedVisitors, setDeclinedVisitors] = useState([]);
  const [currentVisitor, setCurrentVisitor] = useState(null);
  const prevPendingRequestsRef = useRef(pendingRequests);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCallingStatus = async () => {
      try {
        const data = await apiCheckForCalling();

        if (
          JSON.stringify(data.pending_requests) !==
          JSON.stringify(prevPendingRequestsRef.current)
        ) {
          setPendingRequests(data.pending_requests);
          prevPendingRequestsRef.current = data.pending_requests;
        }
      } catch (error) {
        console.error("Error fetching calling status:", error);
      }
    };

    const intervalId = setInterval(fetchCallingStatus, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const findNextVisitor = () => {
      const validVisitor = pendingRequests.find(
        (visitor) => !declinedVisitors.includes(visitor.visitor_id)
      );
      setCurrentVisitor(validVisitor || null);
    };

    findNextVisitor();
  }, [pendingRequests, declinedVisitors]);

  const acceptCall = () => {
    if (currentVisitor) {
      navigate("/visitors/" + currentVisitor.visitor_id + "?callRequest=true");
      setPendingRequests(
        pendingRequests.filter(
          (visitor) => visitor.visitor_id !== currentVisitor.visitor_id
        )
      );
      setCurrentVisitor(null);
    }
  };

  const declineCall = () => {
    if (currentVisitor) {
      setDeclinedVisitors([...declinedVisitors, currentVisitor.visitor_id]);
      console.log(declinedVisitors);
      setPendingRequests(
        pendingRequests.filter(
          (visitor) => visitor.visitor_id !== currentVisitor.visitor_id
        )
      );
      setCurrentVisitor(null);
    }
  };

  return (
    <AppLayoutContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      <div className="grid lg:grid-cols-[13rem_1fr] h-dvh grid-cols-1 relative">
        <div
          className={`${
            sidebarOpen
              ? "overflow-hidden absolute z-50 w-full h-screen bg-white"
              : "hidden"
          } lg:block`}
        >
          <Sidebar />
        </div>

        <main className="overflow-auto p-6 m-0 bg-gray-100 rounded-xl shadow-lg lg:p-8 lg:m-5">
          {currentVisitor && (
            <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
              <p>Visitor {currentVisitor.name} is trying to call you!</p>
              <div className="flex gap-4">
                <button
                  className="p-4 bg-green-500 rounded-lg"
                  onClick={acceptCall}
                >
                  Accept
                </button>
                <button
                  className="p-4 bg-red-500 rounded-lg"
                  onClick={declineCall}
                >
                  Decline
                </button>
              </div>
            </div>
          )}

          <Outlet />
        </main>
      </div>
    </AppLayoutContext.Provider>
  );
}

export default AppLayout;
