import React, { useCallback, useState } from "react";
import {
  useAppMessage,
  useAudioTrack,
  useDaily,
  useLocalSessionId,
  useVideoTrack,
} from "@daily-co/daily-react";

import {
  CameraOn,
  Leave,
  CameraOff,
  MicrophoneOff,
  MicrophoneOn,
  Info,
} from "./Icons";

export default function Tray() {
  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const callObject = useDaily();

  const toggleVideo = useCallback(() => {
    console.log(callObject);
    callObject.setLocalVideo(mutedVideo);
  }, [mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject.setLocalAudio(mutedAudio);
  }, [mutedAudio]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-row gap-3">
        <button onClick={toggleVideo} type="button">
          {mutedVideo ? <CameraOff /> : <CameraOn />}
        </button>
        <button onClick={toggleAudio} type="button">
          {mutedAudio ? <MicrophoneOff /> : <MicrophoneOn />}
        </button>
      </div>
    </div>
  );
}
