import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AppLayout from "./pages/AppLayout";
import Visitors from "./pages/Visitors";
import Live from "./pages/Live";
import LandingPage from "./pages/LandingPage";
import SignUpForm from "./ui/SignUpForm";
import Workflows from "./pages/Workflows";
import DashboardNew from "./Dashborad/DashboardNew";

import SettingsPage from "./pages/SettingsPage";
import ProtectedRoute from "./ui/ProtectedRoute";

import { Toaster } from "react-hot-toast";
import { DailyProvider } from "@daily-co/daily-react";
import Daily from "@daily-co/daily-js";
import { useState, useEffect, useRef } from "react";
import Invitation from "./pages/Invitation";
import { WorkflowProvider } from "./Workflows/WorkflowContextProvider";

function App() {
  const [callObject, setCallObject] = useState(null);
  const hasInitializedCallObject = useRef(false);

  useEffect(() => {
    if (!callObject && !hasInitializedCallObject.current) {
      const newCallObject = Daily.createCallObject();
      hasInitializedCallObject.current = true;
      setCallObject(newCallObject);
    }
  }, [callObject]);

  return (
    <DailyProvider callObject={callObject}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LandingPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AppLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<DashboardNew />} />
            <Route path="/visitors/:id" element={<Live />} />
            <Route path="/visitors" element={<Visitors />} />
            <Route
              path="/workflows"
              element={
                <WorkflowProvider>
                  <Workflows />
                </WorkflowProvider>
              }
            />
            <Route path="/settings" element={<SettingsPage />} />
          </Route>
          <Route path="test" element={<SignUpForm />} />
          <Route path="/invitation/:inviteid" element={<Invitation />} />
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: { duration: 3000 },
          error: {
            duration: 5000,
          },
          style: {
            fontSize: "16px",
            maxWidth: "500px",
            padding: "16px 24px",
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
    </DailyProvider>
  );
}

export default App;
