import { Avatar, AvatarFallback, AvatarImage } from "../components/avatar";
import { Button } from "../components/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/table";
import { Badge } from "../components/badge";
import {
  FiMoreHorizontal,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import apiVisitors from "./VisitorsApis/apiVisitors";
import { useEffect, useState } from "react";
import Spinner from "../ui/Spinner";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const calculateLastLogin = (lastLoginString) => {
  const lastLoginDate = new Date(lastLoginString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - lastLoginDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} m ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} h ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} days ago`;
  }
};

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};

const sortVisitors = (visitors) => {
  return [...visitors].sort((a, b) => {
    if (a.online && !b.online) return -1;
    if (!a.online && b.online) return 1;
    if (a.online === b.online) {
      if (a.online) {
        // For online users, prioritize those with data.name or data.email
        if ((a.data?.name || a.data?.email) && !(b.data?.name || b.data?.email))
          return -1;
        if (!(a.data?.name || a.data?.email) && (b.data?.name || b.data?.email))
          return 1;
      } else {
        // For offline users, sort by last_login
        return new Date(b.last_login) - new Date(a.last_login);
      }
    }
    return 0;
  });
};

const VisitorsTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visitors, setVisitors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageData, setNextPageData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalVisitors, setTotalVisitors] = useState(0);

  const fetchVisitors = async (page) => {
    try {
      setIsLoading(true);
      const data = await apiVisitors(page, 8);
      setTotalVisitors(data.total_visitors);

      return data.visitors;
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const prefetchNextPage = async (page) => {
    setIsLoadingMore(true);
    const nextData = await fetchVisitors(page);
    setNextPageData(nextData);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    const initialFetch = async () => {
      const initialData = await fetchVisitors(currentPage);
      setVisitors(initialData);
      console.log(`initialData`, { initialData });
      await prefetchNextPage(currentPage + 1); // Prefetch the next page
    };
    initialFetch();
  }, [currentPage]);

  const handleNextPage = async () => {
    if (nextPageData.length > 0) {
      setVisitors(nextPageData); // Show the prefetched data
      setCurrentPage((prevPage) => prevPage + 1); // Increment the current page
      await prefetchNextPage(currentPage + 2); // Prefetch the next page
      setNextPageData([]); // Clear the next page data after using it
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      const previousPageData = await fetchVisitors(currentPage - 1);
      setVisitors(previousPageData); // Show the previous page data
      setCurrentPage((prevPage) => prevPage - 1); // Decrement the current page
      await prefetchNextPage(currentPage); // Prefetch the next page
    }
  };

  if (isLoading) {
    return (
      <div className="mt-52">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="py-8">
      <Table className="bg-transparent md:bg-white">
        <TableHeader className="hidden pb-4 md:table-row-group">
          <TableRow className="hover:bg-white">
            <TableHead className="w-[300px]">CUSTOMER</TableHead>
            <TableHead>LAST ACTIVE</TableHead>
            <TableHead>LOCATION </TableHead>
            <TableHead># SESSIONS</TableHead>
            <TableHead>FIRST VISIT </TableHead>
            <TableHead>DEVICE </TableHead>
            <TableHead>LAST PAGE </TableHead>
            <TableHead className="sticky -right-5 z-10 pl-0 bg-white">
              <div className="pl-6 border-l-4 h-[48px] border-gray-100 flex justify-center items-center">
                ACTIONS
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {visitors.map((customer) => (
            <TableRow
              key={customer._id}
              className="grid grid-cols-1 p-4 mb-5 bg-white rounded-lg border-b-2 md:rounded-none md:bg-transparent md:table-row md:border-0 hover:bg-white md:hover:bg-gray-50"
            >
              <TableCell className="order-2 font-medium">
                <div className="flex items-center md:space-x-4">
                  <Avatar className="hidden md:block">
                    <AvatarImage
                      src={customer?.avatarUrl}
                      alt={customer.data?.name || "user avatar"}
                    />
                    <AvatarFallback>
                      {customer?.name ? customer?.name.charAt(0) : "X"}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="font-semibold">
                      {customer?.name || customer._id}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {customer.data?.email || customer.ip}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="order-1 w-full md:w-auto">
                <div className="flex justify-between md:w-[5rem] md:gap-2 md:flex-col md:justify-normal md:items-center">
                  <Badge
                    variant={
                      customer.online === false ? "secondary" : "default"
                    }
                    className="flex justify-center items-center px-0 w-20 text-base"
                  >
                    {customer.online ? "online" : "offline"}
                  </Badge>
                  <span className="text-sm text-muted-foreground">
                    {calculateLastLogin(customer.last_online)}
                  </span>
                </div>
              </TableCell>
              <TableCell className="flex order-3 gap-20 md:order-none md:hidden">
                <ul className="text-gray-500">
                  <li>Country:</li>
                  <li>City:</li>
                  <li># of sessions:</li>
                  <li>Device name:</li>
                  <li>Device type:</li>
                  <li>Last login:</li>
                  <li>Last page visited:</li>
                </ul>
                <ul className="font-semibold text-black">
                  <li className="flex gap-2">
                    <span>{customer.location.country}</span>
                    {flagemojiToPNG(customer.location.country)}
                  </li>
                  <li>{customer.location.city}</li>
                  <li>{customer.total_browsing_sessions}</li>
                  <li>{customer.device.name}</li>
                  <li>{customer.device.type}</li>
                  <li>{formatDate(customer.created_at)}</li>
                  <li>{customer.current_page}</li>
                </ul>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p className="flex gap-2 mb-2">
                  <span>{customer.location.country}</span>
                  {flagemojiToPNG(customer.location.country)}
                </p>
                <p>{customer.location.city}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.total_browsing_sessions}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{formatDate(customer.created_at)}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.device.name}</p>
                <p>{customer.device.type}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.current_page}</p>
              </TableCell>

              <TableCell className="sticky -right-5 z-10 order-4 p-0 m-0 w-full text-right bg-white md:w-auto hover:bg-gray-50">
                <div className="p-4 md:h-[92.5px] md:border-l-4 md:border-gray-100 flex justify-center items-center">
                  <Button
                    variant="outline"
                    size="sm"
                    className={`w-full mr-2 pl-2   ${
                      customer.online === true
                        ? "bg-green-500 hover:bg-green-600"
                        : "bg-black text-white hover:text-white hover:bg-gray-900"
                    }  md:w-28`}
                  >
                    <Link to={`/visitors/${customer._id}`}>
                      {customer.online === true ? "Watch Live" : "View history"}
                    </Link>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Pagination logic here ------------------------------------------ */}
      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={handlePreviousPage}
          disabled={currentPage === 1} // Disable if on the first page
        >
          <FiChevronLeft className="w-4 h-4" />
        </Button>

        <p className="text-sm text-muted-foreground">
          {currentPage} of {Math.ceil(totalVisitors / 8)}
        </p>

        <Button
          variant="outline"
          size="sm"
          onClick={handleNextPage}
          disabled={nextPageData.length === 0} // Disable if no next page data
        >
          <FiChevronRight className="w-4 h-4" />
        </Button>
      </div>
      {/* Pagination logic here ------------------------------------------ */}
    </div>
  );
};

export default VisitorsTable;
