import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../components/card";

export default function VisitorInfoCard({ info }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">{info.title}</CardTitle>
      </CardHeader>
      <CardContent>
        <dl className="space-y-2">
          {Object.entries(info).map(
            ([key, value], index) =>
              key !== "title" && (
                <div key={index} className="flex flex-col min-[350px]:flex-row">
                  <dt className="w-full min-[350px]:w-40 font-medium text-muted-foreground mb-1 min-[350px]:mb-0">
                    {`${key.toUpperCase()}:`}
                  </dt>
                  <dd className="font-semibold break-all min-[350px]:flex-1">
                    {value}
                  </dd>
                </div>
              )
          )}
        </dl>
      </CardContent>
    </Card>
  );
}
