import toast from "react-hot-toast";

const apiVisitorIdentity = async (visitor_id, identity) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    console.log(JSON.stringify({ data: identity })); // Logging identity

    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/organizations/${org_id}/visitors/${visitor_id}/identify`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: identity }),
      }
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response from server:", errorResponse);
      throw new Error("Fetching visitors failed");
    }

    const dataFinal = await response.json();

    toast.success("Visitor identity updated successfully");

    return dataFinal;
  } catch (error) {
    console.error("Error fetching visitors:", error);
    throw error;
  }
};

export default apiVisitorIdentity;
