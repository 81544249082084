const apiUpdateLogedUser = async (newUserData) => {
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  console.log(JSON.stringify(newUserData));
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        method: "PATCH",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUserData),
      }
    );

    if (!response.ok) {
      throw new Error("Updating user failed");
    }

    const data = await response.json();
    return data; // Return fetched data
  } catch (error) {
    console.error("Error updating user:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiUpdateLogedUser;
