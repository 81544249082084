const apiLogin = async (loginData, setLoading) => {
  setLoading(true); // Set loading to true when the request starts

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      }
    );

    const data = await response.json();
    console.log("Login successful:", data);
    localStorage.setItem("agentName", data.user.name);
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("apikey", data.user.api_key);
    localStorage.setItem("orgid", data.user.org_id);
    localStorage.setItem("userid", data.user._id);
    return { success: true, data }; // Return success and data
  } catch (error) {
    console.error("Error during login:", error);
    return { success: false, error }; // Return failure and error message
  } finally {
    setLoading(false); // Set loading to false when the request ends
  }
};

export default apiLogin;
