function Logo() {
  return (
    <img
      className="w-[4.5rem] h-[4.5rem] mt-1 ml-5"
      src="/logo/logo.png"
      alt="logo"
    />
  );
}

export default Logo;
