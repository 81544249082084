import React, { useEffect, useState } from "react";
import { Input } from "../../components/input";
import { Label } from "../../components/label";
import { Textarea } from "../../components/textarea";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../components/select";

export default function WebhookConfig({ workflowData, setWorkflowData }) {
  const [payloadString, setPayloadString] = useState("");

  useEffect(() => {
    // Stringify the payload object when component mounts or workflowData changes
    if (workflowData.action.type === "popup") {
      if (typeof workflowData.action?.notification?.payload === "object") {
        setPayloadString(
          JSON.stringify(workflowData.action?.notification?.payload, null, 2)
        );
      } else {
        setPayloadString(workflowData.action?.notification?.payload || "");
      }
    } else {
      if (typeof workflowData.action?.payload === "object") {
        setPayloadString(JSON.stringify(workflowData.action?.payload, null, 2));
      } else {
        setPayloadString(workflowData.action?.payload || "");
      }
    }
  }, [
    workflowData.action?.payload,
    workflowData.action?.type,
    workflowData.action?.notification?.payload,
  ]);

  useEffect(() => {
    console.log(workflowData);
  }, [workflowData]);

  const handleInputChange = (e) => {
    const isPopup = workflowData.action.type === "popup";

    const { id, value } = e.target;
    if (id === "payload") {
      setPayloadString(value);
      if (isPopup) {
        setWorkflowData((prev) => ({
          ...prev,
          action: {
            ...prev.action,
            notification: { ...prev.action.notification, payload: value },
          },
        }));
      } else {
        setWorkflowData((prev) => ({
          ...prev,
          action: { ...prev.action, payload: value },
        }));
      }
    } else {
      if (isPopup) {
        setWorkflowData((prev) => ({
          ...prev,
          action: {
            ...prev.action,
            notification: { ...prev.action.notification, [id]: value },
          },
        }));
      } else {
        setWorkflowData((prev) => ({
          ...prev,
          action: { ...prev.action, [id]: value },
        }));
      }
    }
  };

  const handleMethodChange = (value) => {
    const isPopup = workflowData.action.type === "popup";
    if (isPopup) {
      setWorkflowData((prev) => ({
        ...prev,
        action: {
          ...prev.action,
          notification: { ...prev.action.notification, method: value },
        },
      }));
    } else {
      setWorkflowData((prev) => ({
        ...prev,
        action: { ...prev.action, method: value },
      }));
    }
  };
  const isPopup = workflowData.action.type === "popup";
  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Webhook Configuration</h2>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="payload">Payload</Label>
          <Textarea
            id="payload"
            placeholder={`{
    "message": "Type message here",
    "another_field": "Another value"
  }`}
            className="h-32 font-mono"
            value={payloadString}
            onChange={handleInputChange}
            required
          />
          <p className="text-sm text-red-500">This has to be JSON format!</p>
        </div>
        <div className="space-y-2">
          <Label htmlFor="url">URL</Label>
          <Input
            id="url"
            placeholder="https://api.example.com/webhook"
            value={
              isPopup
                ? workflowData.action.notification.url
                : workflowData.action.url
            }
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="method">Method</Label>
          <Select
            value={
              isPopup
                ? workflowData.action.notification.method
                : workflowData.action.method
            }
            onValueChange={handleMethodChange}
          >
            <SelectTrigger id="method">
              <SelectValue placeholder="Select method" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="POST">POST</SelectItem>
              <SelectItem value="GET">GET</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}
