import React, { useState, useEffect } from "react";
import { Button } from "../components/button";
import { Input } from "../components/input";
import { Label } from "../components/label";
import {
  IoEyeOutline as Eye,
  IoEyeOffOutline as EyeOff,
} from "react-icons/io5";
import apiLogin from "../Signup/apiLogin";
import apiRegister from "../Signup/apiRegister";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get("redirect");

    const userId = localStorage.getItem("userid");
    if (userId) {
      navigate(redirectUrl || "/dashboard");
    }
  }, [navigate, location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    let hasError = false;

    if (!email) {
      setEmailError("You need to input email");
      hasError = true;
    }

    if (isRegisterMode && !name) {
      setNameError("You need to input name");
      hasError = true;
    }

    if (!password) {
      setPasswordError("You need to input password");
      hasError = true;
    }

    if (hasError) return;

    let result;
    if (isRegisterMode) {
      result = await apiRegister({ email, name, password });
    } else {
      result = await apiLogin({ email, password }, setLoading);
    }

    if (result.success) {
      setEmail("");
      setPassword("");
      setName("");
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get("redirect");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/dashboard");
      }
      toast.success(
        isRegisterMode ? "Registered successfully!" : "Logged in successfully!"
      );
    } else {
      console.error(result.error);
      setPasswordError(
        isRegisterMode ? "Registration failed" : "Incorrect password"
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleMode = () => {
    setIsRegisterMode(!isRegisterMode);
  };

  return (
    <div className="flex flex-col justify-center items-center pt-20 bg-white">
      <div className="space-y-8 w-full max-w-md">
        <div className="text-left">
          <h2 className="mt-6 text-5xl font-bold text-gray-900">
            {isRegisterMode ? "Register" : "Log in"}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {isRegisterMode
              ? "Register a new account here."
              : "Log back into your F2F workspace."}
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            {isRegisterMode && (
              <div>
                <Label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="block px-3 py-2 mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && (
                  <p className="mt-1 text-sm text-red-600 cursor-default">
                    {nameError}
                  </p>
                )}
              </div>
            )}
            <div>
              <Label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="block px-3 py-2 mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black autofill:bg-white autofill:text-gray-900"
                placeholder="johnsmith@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  WebkitBoxShadow: "0 0 0 30px white inset",
                  WebkitTextFillColor: email ? "#000000" : "#D3D3D3",
                  color: email ? "#000000" : "#D3D3D3",
                }}
              />
              {emailError && (
                <p className="mt-1 text-sm text-red-600 cursor-default">
                  {emailError}
                </p>
              )}
            </div>

            <div>
              <Label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </Label>
              <div className="relative mt-1">
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  className="block px-3 py-2 pr-10 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="flex absolute inset-y-0 right-0 items-center pr-3"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <EyeOff className="w-5 h-5 text-gray-400" />
                  ) : (
                    <Eye className="w-5 h-5 text-gray-400" />
                  )}
                </button>
              </div>
              {passwordError && (
                <p className="mt-1 text-sm text-red-600 cursor-default">
                  {passwordError}
                </p>
              )}
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className={`flex justify-center px-4 py-2 w-full text-sm font-medium text-white bg-black rounded-md border border-transparent shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black`}
              disabled={loading}
            >
              {loading ? "Loading..." : isRegisterMode ? "Register" : "Log In"}
            </Button>
          </div>
        </form>

        <div className="text-sm">
          <a
            href="#"
            className="font-medium text-black hover:underline"
            onClick={toggleMode}
          >
            {isRegisterMode
              ? "Already have an account?"
              : "Create a new account"}
          </a>
        </div>
      </div>
    </div>
  );
}
