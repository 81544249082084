import { useContext } from "react";
import Logo from "./Logo";
import MainNav from "./MainNav";
import { AppLayoutContext } from "../pages/AppLayout";
import { IoMdClose } from "react-icons/io";

function Sidebar() {
  const { sidebarOpen, setSidebarOpen } = useContext(AppLayoutContext);
  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex items-center justify-between">
        <Logo />
        {sidebarOpen && (
          <button
            onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
            className="text-5xl"
          >
            <IoMdClose />
          </button>
        )}
      </div>

      <MainNav />
    </div>
  );
}

export default Sidebar;
